<div class="flex flex-col w-full">
  <div class="w-full fields-selector-table field flex flex-row md:flex-nowrap flex-wrap gap-1 w-full algin-items-start">
    @if (!floatLabel) {
      @if (label && !instanceInFormRepeater) {
        <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
          >{{ label }}
          @if (isControlRequired && viewMode != 'view') {
            <span class="text-red-500"> *</span>
          }
          @if (resetFieldCheckVisable) {
            <br />
            <app-reset-switch-input
              [checked]="!resetable"
              (onChange)="setFieldResetable($event)"
            ></app-reset-switch-input>
          }
          <br />
          @if (control?.dirty) {
            @if (control?.hasError('required')) {
              <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
            }
            @if (control?.hasError('minlength')) {
              <small class="p-error p-invalid">
                {{ label }} must have a minimum length of {{ control?.errors?.minlength?.requiredLength }}
              </small>
            }
            @if (control?.hasError('maxlength')) {
              <small class="p-error p-invalid">
                {{ label }} must have a maximum length of {{ control?.errors?.maxlength?.requiredLength }}
              </small>
            }
            @if (customError && control?.invalid) {
              <small class="p-error p-invalid">{{ customErrorLabel }}</small>
            }
          }
          @if (helpText) {
            <small class="text-500 block">{{ helpText }}</small>
          }
        </label>
      }
    }
    @if (floatLabel) {
      @if (label && !instanceInFormRepeater) {
        <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
          >{{ label }}
          @if (isControlRequired && viewMode != 'view') {
            <span class="text-red-500"> *</span>
          }
          @if (resetFieldCheckVisable) {
            <br />
            <app-reset-switch-input
              [checked]="!resetable"
              (onChange)="setFieldResetable($event)"
            ></app-reset-switch-input>
          }
          <br />
          @if (control?.dirty) {
            @if (control?.hasError('required')) {
              <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
            }
            @if (control?.hasError('minlength')) {
              <small class="p-error p-invalid">
                {{ label }} must have a minimum length of {{ control?.errors?.minlength?.requiredLength }}
              </small>
            }
            @if (control?.hasError('maxlength')) {
              <small class="p-error p-invalid">
                {{ label }} must have a maximum length of {{ control?.errors?.maxlength?.requiredLength }}
              </small>
            }
            @if (customError && control?.invalid) {
              <small class="p-error p-invalid">{{ customErrorLabel }}</small>
            }
          }
          @if (helpText) {
            <small class="text-500 block">{{ helpText }}</small>
          }
        </label>
      }
    }
    <p-table
      class="w-full fields-table"
      [value]="tableItems"
      [columns]="cols"
      [reorderableColumns]="false"
      responsiveLayout="scroll"
      [class.ng-invalid]="control?.invalid"
      [class.ng-dirty]="control?.dirty"
    >
      @if (viewMode != 'view') {
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between gap-2">
            <!-- <h5 class="m-0">Assessment Items</h5> -->
            <span class="p-input-icon-left flex gap-2">
              <p-autoComplete
                placeholder="Search Field name..."
                class="w-full"
                styleClass="pl-0 pr-0 w-full"
                [(ngModel)]="searchQ"
                [ngModelOptions]="{ standalone: true }"
                (onSelect)="onSelectItem($event)"
                [suggestions]="searchItems$ | async"
                (completeMethod)="search($event.query)"
                field="name"
                [delay]="600"
              >
                <ng-template let-option pTemplate="item">
                  <div class="flex flex-1 gap-2">
                    <app-badge-item
                      [styleClass]="'text-xs'"
                      [status]="option?.recordStatus || 'cyan'"
                      [text]="option.code"
                    ></app-badge-item>
                    <div>{{ option.name }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
              <!-- <div class="flex-grow5" *ngIf="showAddItem"> -->
              <div class="flex-grow">
                <app-button [action]="showAddItemAction"></app-button>
              </div>
              <!-- <input appMaxCharacterValidator (keyup)="search(getSearchValue($event))" class="w-full" pInputText type="text" placeholder="Search Items..." /> -->
            </span>
          </div>
        </ng-template>
      }
      <ng-template pTemplate="header" let-columns>
        <tr>
          @if (viewMode != 'view') {
            <th style="width: 3rem"></th>
          }
          @for (col of columns; track col) {
            <th>
              {{ col.name }}
            </th>
          }
          @if (viewMode != 'view') {
            <th>Actions</th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
        <tr [pReorderableRow]="index">
          @if (viewMode != 'view') {
            <td>
              <span class="pi pi-bars" pReorderableRowHandle></span>
            </td>
          }
          @for (col of columns; track col) {
            <td>
              <app-view-switch
                [viewType]="col.dataType"
                [data]="col.passRowAsData ? rowData : getElementValue(col.key, rowData)"
                [options]="col.options"
              >
              </app-view-switch>
            </td>
          }
          @if (viewMode != 'view') {
            <td>
              <app-button-list
                class="w-full"
                [itemClass]=""
                [actionList]="actions"
                [listType]="'list'"
                [commandData]="index"
                [displayData]="rowData"
                [appendTo]="'body'"
              ></app-button-list>
              <!-- <ul class="list-none m-0">
          <li *ngFor="let action of actions" class="inline-block">
                                <button pbutton="" pripple="" type="button" (click)="
                                            $event.stopPropagation();
                                            onActionClick({
                                                row: { data: rowData },
                                                action: action,
                                                index: index
                                            })
                                        " icon="pi pi-times"
              class="p-element  p-ripple p-button-rounded p-button-danger p-button-text mr-2 mb-2 p-button p-component p-button-icon-only">
              <span class="p-button-icon pi pi-trash" aria-hidden="true"></span>
              <span aria-hidden="true" class="p-button-label">&nbsp;</span>
              <span class="p-ink"
                                        style="
                                                height: 32px;
                                                width: 32px;
                                                top: -6px;
                                                left: 0.5px;
                                            "></span>
            </button>
          </li>
        </ul> -->
              <!-- <p-button icon="pi pi-search" styleClass="p-button-success" [style]="{'margin-right': '.5em'}"></p-button> -->
              <!-- <p-button icon="pi pi-pencil" styleClass="p-button-warning"></p-button> -->
            </td>
          }
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
